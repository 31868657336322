import { Box, ResponsiveContext } from 'grommet';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DocumentText, Organization, Projects, SettingsOption, UserAdmin,
} from 'grommet-icons';
import {
  AnchorInterdev, SectionSideBarContainer, TitleSectionSideBar,
} from './SideBarNavStyles';
import useAppContext from '../../../Context/useAppContext';

export interface SidebarNavProps { }

const SidebarNav: React.FC<SidebarNavProps> = ({ }): JSX.Element => {
  const size = useContext(ResponsiveContext);

  const {
    state: { IsAuthenticated, Owner },
  } = useAppContext();

  const navigate = useNavigate();

  const onCadastroProjetoClick = (): void => {
    navigate('/form/projeto');
  };

  const onPermissoesClick = (): void => {
    navigate('/form/permissoes');
  };

  const onTestingErrorClick = (): void => {
    navigate('/owner/testingError');
  };

  const onImportCsvClick = (): void => {
    navigate('/auth/importCsv');
  };

  const onManageUsersClick = (): void => {
    navigate('/owner/manageUsers');
  };

  const onManageEmpresasClick = (): void => {
    navigate('/owner/manageEmpresas');
  };

  const onManageVideosClick = (): void => {
    navigate('/owner/manageVideos');
  };

  const onManageEquipamentosClick = (): void => {
    navigate('/owner/manageEquipamentos');
  };

  const onConclusaoClick = (): void => {
    navigate('/form/conclusao');
  };

  const onAddRelatorioClick = (): void => {
    navigate('/form/addrelatorio');
  };

  const onAddRdoClick = (): void => {
    navigate('/form/addrdo');
  };

  const onAcessosClick = (): void => {
    navigate('/form/acessos');
  };

  return (
    <Box
      fill={size === 'small' ? 'horizontal' : 'vertical'}
      pad="medium"
      direction="column"
      elevation="large"
    >
      <SectionSideBarContainer>
        <Organization color="brand" size="16px" />
        <TitleSectionSideBar color="brand">Empresa</TitleSectionSideBar>
      </SectionSideBarContainer>
      <SectionSideBarContainer>
        <SettingsOption color="brand" size="16px" />
        <TitleSectionSideBar color="brand">Equipamento</TitleSectionSideBar>
      </SectionSideBarContainer>
     
      <SectionSideBarContainer>
        <Projects color="brand" size="16px" />
        <TitleSectionSideBar color="brand">Projeto</TitleSectionSideBar>
      </SectionSideBarContainer>
      <SectionSideBarContainer>
        <UserAdmin color="brand" size="16px" />
        <TitleSectionSideBar color="brand">Admin</TitleSectionSideBar>
      </SectionSideBarContainer>
      <SectionSideBarContainer>
        <DocumentText color="brand" size="16px" />
        <TitleSectionSideBar color="brand">Forms***</TitleSectionSideBar>
      </SectionSideBarContainer>

      <AnchorInterdev
        onClick={onCadastroProjetoClick}
        title="CadastroProjeto"
        label="Projeto"
      />
      <AnchorInterdev
        onClick={onPermissoesClick}
        title="Permissoes"
        label="Permissões"
      />
      <AnchorInterdev
        onClick={onConclusaoClick}
        title="Conclusao"
        label="Conclusao"
      />
      <AnchorInterdev
        onClick={onAddRelatorioClick}
        title="Add Relatório"
        label="Add Relatório"
      />
      <AnchorInterdev
        onClick={onAddRdoClick}
        title="Add RDO"
        label="Add RDO"
      />
      <AnchorInterdev
        onClick={onAcessosClick}
        title="Acessos"
        label="Acessos"
      />
      {
        (IsAuthenticated) && (
          <>
            <SectionSideBarContainer>
              <DocumentText color="brand" size="16px" />
              <TitleSectionSideBar color="brand">Conectado***</TitleSectionSideBar>
            </SectionSideBarContainer>
            <AnchorInterdev
              onClick={onImportCsvClick}
              title="Import CSV"
              label="Import CSV"
            />
          </>
        )
      }

      {
        (Owner) && (
          <>
            <SectionSideBarContainer>
              <DocumentText color="brand" size="16px" />
              <TitleSectionSideBar color="brand">Owner***</TitleSectionSideBar>
            </SectionSideBarContainer>
            <AnchorInterdev
              onClick={onManageEmpresasClick}
              title="Gerenciar Empresas"
              label="Gerenciar Empresas"
            />
            <AnchorInterdev
              onClick={onManageEquipamentosClick}
              title="Gerenciar Equipamentos"
              label="Gerenciar Equipamentos"
            />
            <AnchorInterdev
              onClick={onManageUsersClick}
              title="Gerenciar Usuários"
              label="Gerenciar Usuários"
            />
            <AnchorInterdev
              onClick={onManageVideosClick}
              title="Gerenciar Vídeos"
              label="Gerenciar Vídeos"
            />
            <AnchorInterdev
              onClick={onTestingErrorClick}
              title="Testar Erros"
              label="Testar Erros"
            />
          </>
        )
      }
    </Box>
  );
};

SidebarNav.displayName = 'SidebarNav';

export default SidebarNav;
