import React from 'react';
import {
  Box, Button, FormExtendedEvent, FormField, TextInput, SelectMultiple,
} from 'grommet';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { onEmailValidationRegExp } from '../../../../utils/onEmailRegexValidation';
import InterdevSwitch from '../../InterdevSwitch/InterdevSwitch';

export interface PermissoesFormProps {
  initialValues: {
    userName: string;
    userEmail: string;
    isAdmin: boolean;
    isActive: boolean;
    fullAccess: boolean;
    plantAccess: string[];
  };
  onSubmit: (values: {
    userName: string;
    userEmail: string;
    isAdmin: boolean;
    isActive: boolean;
    fullAccess: boolean;
    plantAccess: string[];
  }) => void;
  plantsList: string[];
}

const PermissoesForm: React.FC<PermissoesFormProps> = ({ initialValues, onSubmit, plantsList }): JSX.Element => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [values, setValues] = React.useState(initialValues);

  const onChange = (nextValues: typeof initialValues): void => setValues(nextValues);

  const handleSubmit = async (evt: FormExtendedEvent<typeof initialValues>): Promise<void> => {
    setSubmitting(true);
    onSubmit(evt.value);
  };

  const validateName = (value: string): string | undefined => {
    if (value.length < 3 || value.length > 100) {
      return 'O nome deve ter entre 3 e 100 caracteres';
    }
  };

  const activeChangeAdmin = (value: boolean ): void => {
    setValues({ ...values, isAdmin: value });
  };

  const activeChangeActive = (value: boolean ): void => {
    setValues({ ...values, isActive: value });
  };

  const activeChangeAccess = (value: boolean ): void => {
    setValues({ ...values, fullAccess: value });
  };

  const validateEmail = onEmailValidationRegExp('Email inválido');

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <FormField
        label="Nome do Usuário"
        name="userName"
        width="100%"
        validate={validateName}
        required
      >
        <TextInput
          name="userName"
          aria-label="Por favor informe o nome do usuário"
          title="Por favor informe o nome do usuário"
          placeholder="Nome do usuário"
        />
      </FormField>

      <FormField
        label="Email do Usuário"
        name="userEmail"
        width="100%"
        validate={validateEmail}
        required
      >
        <TextInput
          name="userEmail"
          aria-label="Por favor informe o email do usuário"
          title="Por favor informe o email do usuário"
          placeholder="email@exemplo.com"
        />
      </FormField>

      <InterdevSwitch
        activeLabel="Administrador"
        inactiveLabel="Usuário"
        value={values.isAdmin || false}
        onChange={activeChangeAdmin}
        name="isAdmin"
        title="Alterar a permissão do usuário"
        text="Permissão do usuário"
      />

      <InterdevSwitch
        activeLabel="Ativo"
        inactiveLabel="Inativo"
        value={values.isActive || false}
        onChange={activeChangeActive}
        name="isActive"
        title="Alterar o Status do usuário"
        text="Status do usuário"
      />

      <InterdevSwitch
        activeLabel="Sim"
        inactiveLabel="Não"
        value={values.fullAccess || false}
        onChange={activeChangeAccess}
        name="fullAccess"
        title="Acesso completo"
        text="Acesso completo"
      />

      <FormField
        label="Acesso por Planta"
        name="plantAccess"
        width="100%"
        required
      >
        <SelectMultiple
          options={plantsList}
          value={values.plantAccess}
          onChange={({ value }) => setValues({ ...values, plantAccess: value })}
          placeholder="Selecione uma ou mais plantas"
        />
      </FormField>

      <Box
        direction="row"
        gap="small"
        justify="end"
        margin={{
          top: 'medium',
          bottom: 'medium',
        }}
      >
        <Button
          type="submit"
          label="Salvar"
          title="Salvar permissões"
          disabled={submitting}
          primary
          reverse
        />
      </Box>
    </FormWrapper>
  );
};

PermissoesForm.displayName = 'PermissoesForm';

export default PermissoesForm;
