import React from 'react';
import { DocumentImage, DocumentPdf } from 'grommet-icons';
import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';
import { Button } from 'grommet';

export interface ManageEmpresasDocumentoDownloadProps {
  documento: IEmpresaDocumento;
}

const ManageEmpresasDocumentoDownload: React.FC<ManageEmpresasDocumentoDownloadProps> = ({ documento }): JSX.Element => {
  const Icon = (): JSX.Element => {
    if (documento.Upload?.UploadMimeType === 'application/pdf') {
      return (
        <DocumentPdf />
      );
    }

    return (
      <DocumentImage />
    );
  };

  return (
    <Button
      icon={<Icon />}
      onClick={(): void => {
        window.open(`/api/empresa/documento/download/${documento.Guid}`, '_blank');
      }}
    />
  );
};

ManageEmpresasDocumentoDownload.displayName = 'ManageEmpresasDocumentoDownload';

export default ManageEmpresasDocumentoDownload;
