import React from 'react';
import { IProjeto } from '../../../../Interfaces/IProjeto';
import ProjetoForm from './ProjetoForm';
import PageWrapper from '../../PageWrapper/PageWrapper';
import { Box } from 'grommet';

export interface ProjetoPageProps {}

const ProjetoPage: React.FC<ProjetoPageProps> = ({}): JSX.Element => {
  const initialValues: IProjeto = {
    Guid: '',
    TituloProjeto: '',
    NumeroProjeto: '',
    Servico: false,
    Escopo: '',
    Cliente: 0,
    Planta: 0,
    Previsao: 0,
    DataInicio: '',
    DataTermino: '',
    LocalAtendimento: '',
    EquipeTecnica: 0,
    Equipamento: 0,
    Observacoes: '',
  };

  return (
        <PageWrapper>
          <Box
            width="xlarge"
            alignSelf="center"
            pad="xlarge"
          >
            <ProjetoForm
              initialValues={initialValues}
              onSubmit={(values: IProjeto): void => {
                console.log(values);
              }}
            />
          </Box>
        </PageWrapper>
  );
};

ProjetoPage.displayName = 'ProjetoPage';

export default ProjetoPage;
