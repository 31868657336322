import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';
import { Edit } from 'grommet-icons';
import ManageEmpresasDocumentoDownload from './ManageEmpresasDocumentoDownload';

export interface ManageEmpresasDocumentosActionsProps {
  documento: IEmpresaDocumento;
  refreshData: () => void;
}

const ManageEmpresasDocumentosActions: React.FC<ManageEmpresasDocumentosActionsProps> = ({ documento }): JSX.Element => {
  const navigate = useNavigate();

  const onEditDocumentoClick = (): void => {
    navigate(`/owner/editarDocumento/${documento.Guid}`);
  };

  return (
    <>
      <Box
        direction="row"
        gap="none"
        pad="none"
      >
        <ManageEmpresasDocumentoDownload
          documento={documento}
        />
        <Button
          icon={<Edit />}
          onClick={onEditDocumentoClick}
        />
      </Box>
    </>
  );
};

ManageEmpresasDocumentosActions.displayName = 'ManageEmpresasDocumentosActions';

export default ManageEmpresasDocumentosActions;
