import {
  Box, Button, FormField, TextInput, FormExtendedEvent, ResponsiveContext,
} from 'grommet';
import React, { useContext, useState } from 'react';
import { IUser } from '../../../../Interfaces/IUser';
import InterdevSwitch from '../../InterdevSwitch/InterdevSwitch';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { onEmailValidationRegExp } from '../../../../utils/onEmailRegexValidation';
import { CircleInformation } from 'grommet-icons';

export interface UsuarioFormProps {
  initialValues: IUser;
  onSubmit: (values: IUser) => void;
  onCancel?: () => void;
  editing: boolean;
}

const UsuarioForm: React.FC<UsuarioFormProps> = ({ initialValues, onSubmit, onCancel, editing }): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<IUser>(initialValues);
  const onChange = (nextValues: IUser): void => setValues(nextValues);

  const size = useContext(ResponsiveContext);

  const onClear = (): void => {
    if (onCancel) {
      onCancel();
    } else {
      setValues(initialValues);
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IUser>): Promise<void> => {
    const { value } = evt;
    setSubmitting(true);

    await onSubmit(value);

    setSubmitting(false);
  };

  const activeChange = (value: boolean): void => {
    setValues({ ...values, Active: value });
  };

  const technicianChange = (value: boolean): void => {
    const newTechDock: string = (value) ? values.TechDoc! : '';

    setValues({ ...values, Technician: value, TechDoc: newTechDock });
  };

  const validateLength = (value: string): string | undefined => {
    if (value.length < 5) {
      return 'Deve ter pelo menos 5 caracteres';
    }
    if (value.length > 95) {
      return 'Deve ter no máximo 95 caracteres';
    }
    return undefined;
  };

  const validateConfirmEmail = (value: string): string | undefined => {
    if (value !== values.Email) {
      return 'Emails não conferem';
    }
    return undefined;
  };

  const validateTechDoc = (value: string): string | undefined => {
    if (values.Technician && value.length < 3) {
      return 'Documento obrigatório para técnicos';
    }
    return undefined;
  };

  return (
    <Box pad="medium">
      <FormWrapper
        values={values}
        onChange={onChange}
        onSubmit={handleSubmit}
      >
        <Box
          gap="small"
          direction={(size === 'small') ? 'column' : 'row'}
        >
          <FormField
            label="Nome"
            name="FirstName"
            width="100%"
            validate={validateLength}
            required
          >
            <TextInput
              name="FirstName"
              title="Nome"
            />
          </FormField>

          <FormField
            label="Sobrenome"
            name="LastName"
            width="100%"
            validate={validateLength}
            required
          >
            <TextInput
              name="LastName"
              title="Nome"
            />
          </FormField>
        </Box>

        <Box
          gap="small"
          direction={(size === 'small') ? 'column' : 'row'}
        >
          <FormField
            label="Email"
            name="Email"
            width="100%"
            validate={onEmailValidationRegExp('Email inválido')}
            disabled={editing}
            required
          >
            <TextInput
              name="Email"
              title={(editing) ? 'Email (só pode ser alterado por Proprietários ou pelo próprio usuário)' : 'Email'}
              disabled={editing}
              icon={(editing) ? <CircleInformation /> : undefined}
              reverse
            />
          </FormField>

          <FormField
            label="Confirmar Email"
            name="ConfirmEmail"
            width="100%"
            validate={validateConfirmEmail}
            disabled={editing}
            required
          >
            <TextInput
              name="ConfirmEmail"
              title={(editing) ? 'Confirmar Email (só pode ser alterado por Proprietários ou pelo próprio usuário)' : 'Confirmar Email'}
              disabled={editing}
              icon={(editing) ? <CircleInformation /> : undefined}
              reverse
            />
          </FormField>
        </Box>

        <Box
          gap="small"
          direction={(size === 'small') ? 'column' : 'row'}
        >
          <Box
            direction={(size === 'small') ? 'column' : 'row'}
            width="100%"
          >
            <InterdevSwitch
              activeLabel="Ativo"
              inactiveLabel="Inativo"
              value={values.Active || false}
              onChange={activeChange}
              name="Active"
              title="Alterar o Status do usuário"
              text="Status do usuário"
            />

            <InterdevSwitch
              activeLabel="Técnico"
              inactiveLabel="Usuário"
              value={values.Technician || false}
              onChange={technicianChange}
              name="Technician"
              title="Alterar o Status técnico"
              text="Técnico?"
            />
          </Box>

          {
            (values.Technician) && (
              <FormField
                label="Número do Registro (CREA/CFT)"
                name="TechDoc"
                width="100%"
                validate={validateTechDoc}
              >
                <TextInput
                  name="TechDoc"
                  title="Número do Registro"
                />
              </FormField>
            )
          }
        </Box>

        <Box
          direction="row"
          gap="small"
          justify="start"
          margin={{ top: 'medium', bottom: 'medium' }}
        >
          <Button
            type="submit"
            label="Salvar"
            title="Salvar"
            disabled={submitting}
            primary
            reverse
          />
          <Button
            label="Cancelar"
            title="Cancelar"
            onClick={onClear}
            reverse
          />
        </Box>
      </FormWrapper>
    </Box>
  );
};

export default UsuarioForm;
