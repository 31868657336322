import {
  Box, Button, Image, ResponsiveContext,
} from 'grommet';
import { Close, Login, Menu } from 'grommet-icons';
import React, { useContext } from 'react';
import UserMenuOptions from './UserMenuOptions';
import ConfigMenuOptions from './ConfigMenuOptions';
import useAppContext from '../Context/useAppContext';
import { useNavigate } from 'react-router-dom';

export interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = (): JSX.Element => {
  const size = useContext(ResponsiveContext);
  const navigate = useNavigate();

  const onLogoClick = (): void => {
    navigate('/');
  };

  const {
    state: { Drawer, IsAuthenticated },
    dispatch,
  } = useAppContext();

  const changeMenu = (): void => {
    dispatch({
      type: 'changeDrawer',
      value: !Drawer,
    });
  };

  const onNavigateToLogin = (): void => {
    navigate('/login');
  };

  return (
    <Box
      gridArea="header"
      elevation="medium"
      fill="horizontal"
      align="center"
      justify="evenly"
      direction="row"
      pad="small"
    >
      <Box
        justify="start"
        fill="horizontal"
        gap="small"
        direction="row"
      >
        {
          (IsAuthenticated) && (
            <Button
              onClick={changeMenu}
              title={(Drawer) ? 'Close Menu' : 'Open Menu'}
              icon={(Drawer) ? <Close color="brand" /> : <Menu color="brand" />}
            />
          )
        }
        <Image
          src="/logo.png"
          alt="Interdev Cloud Computing"
          style={{
            maxWidth: (size === 'small') ? '148px' : '220px',
            cursor: 'pointer',
          }}
          onClick={onLogoClick}
        />
      </Box>
      <Box
        justify="end"
        align="center"
        fill="horizontal"
        direction="row"
        gap="none"
      >
        {
          (IsAuthenticated) ? (
            <UserMenuOptions />
          ) : (
            <Button
              title="Fazer login"
              icon={<Login color='brand' />}
              onClick={onNavigateToLogin}
            />
          )
        }
        <ConfigMenuOptions />
      </Box>
    </Box>
  );
};

AppHeader.displayName = 'AppHeader';

export default AppHeader;
