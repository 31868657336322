import { Box, Button } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../PageWrapper/PageWrapper';
import PageTitle from '../PageTitle/PageTitle';
import { IPlanta } from '../../../Interfaces/IPlanta';
import { IPagination, Pagination as PaginationClass } from '../../../Interfaces/IPagination';
import getPlantas from './Helpers/getPlantas';
import Loading from '../Loading/Loading';
import totalPages from '../Pagination/Helpers/totalPages';
import Pagination from '../Pagination/Pagination';
import PageSize from '../Pagination/Components/PageSize';
import ManagePlantasTable from './Components/ManagePlantasTable';
import PesquisarPlantas from './Components/PesquisarPlanta/PesquisarPlantas';

export interface ManagePlantasProps {}

const ManagePlantas: React.FC<ManagePlantasProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onAddPlantalick = (): void => {
    navigate(`/owner/adicionarPlanta/${guid}`);
  };

  const [plantas, setPlantas] = useState<IPagination<IPlanta[]>>(new PaginationClass(1, 30, 30, []));
  const [loading, setLoading] = useState<boolean>(true);

  const setData = (data: IPagination<IPlanta[]>): void => {
    setPlantas(data);
  };

  const initialPagination: IPagination<IPlanta[]> = new PaginationClass(1, 30, 30);

  const getData = async (pagination: IPagination = initialPagination): Promise<void> => {
    setLoading(true);

    await getPlantas(guid!, pagination)
      .then((data): void => {
        if (data.Success) {
          setPlantas(data.Object!);
        } else {
          toast.error('Erro ao buscar plantas');
        }

        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar plantas');
      });
  };

  const updatePage = (newPage: string): void => {
    const newPagination = new PaginationClass(parseInt(newPage, 10), plantas.PageSize);
    getData(newPagination);
  };

  const updatePageSize = (newSize: string): void => {
    const newPagination = new PaginationClass(1, parseInt(newSize, 10));
    getData(newPagination);
  };

  useEffect((): void => {
    getData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Gerenciar Plantas"
      />
      <Box
        width="xlarge"
        align="end"
      >
        <Button
          primary
          label="Adicionar Planta"
          title="Adicionar Planta"
          onClick={onAddPlantalick}
          icon={<Add />}
          reverse
        />
      </Box>
      {
        (loading) ? (<Loading />) : (
          <Box
            fill="horizontal"
            direction="column"
            gap="none"
            margin={{
              top: 'medium',
            }}
          >
            <PesquisarPlantas
              refreshData={getData}
              setResults={setData}
              empresaGuid={guid!}
            />
            <Pagination
              page={plantas.Page!}
              last={totalPages(plantas.Total || 0, plantas.PageSize!)}
              onChange={updatePage}
            />
            <ManagePlantasTable
              plantas={plantas.Object || []}
              refreshData={getData}
            />
            <PageSize
              size={plantas.PageSize!}
              onChange={updatePageSize}
            />
            <Pagination
              page={plantas.Page!}
              last={totalPages(plantas.Total || 0, plantas.PageSize!)}
              onChange={updatePage}
            />
          </Box>
        )
      }
    </PageWrapper>
  );
};

ManagePlantas.displayName = 'ManagePlantas';

export default ManagePlantas;
