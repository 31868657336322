import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProjetoPage from '../Forms/Projeto/ProjetoPage';
import PermissoesPage from '../Forms/Permissoes/PermissoesPage';
import Home from '../Home/Home';
import LoginPage from '../Login/LoginPage';
import PasswordRecovery from '../PasswordRecovery/PasswordRecovery';
import useTwoFactorRouter from './Components/useTwoFactorRouter';
import useIsAuthenticatedRouter from './Components/useIsAuthenticatedRouter';
import useIsOwnerRouter from './Components/useIsOwnerRouter';
import Dashboard from '../Dashboard/Dashboard';
import ConclusaoPage from '../Forms/Conclusao/ConclusaoPage';
import AddRelatorioPage from '../Forms/AddRelatorio/AddRelatorioPage';
import AddImagemPage from '../Forms/AddImagem/AddImagemPage';
import AddRdoPage from '../Forms/AddRdo/AddRdoPage';
import Error404 from '../Error404/Error404';
import Error403 from '../Error403/Error403';
import AcessosPage from '../Forms/Acesso/AcessosPage';
import Error500 from '../Error500/Error500';


export interface MainRouterProps {}

const MainRouter: React.FC<MainRouterProps> = ({}): JSX.Element => {
  const IsTwoFactorRouter = useTwoFactorRouter();
  const IsAuthenticatedRouter = useIsAuthenticatedRouter();
  const IsOwnerRoute = useIsOwnerRouter();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/passwordRecovery" element={<PasswordRecovery />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/404" element={<Error404 />} />
      <Route path="/403" element={<Error403 />} />
      <Route path="/500" element={<Error500 />} />

      <Route path="/form/projeto" element={<ProjetoPage />} />
      <Route path="/form/permissoes" element={<PermissoesPage />} />
      <Route path="/form/conclusao" element={<ConclusaoPage />} />
      <Route path="/form/addrelatorio" element={<AddRelatorioPage />} />
      <Route path="/form/addimagem" element={<AddImagemPage />} />
      <Route path="/form/addrdo" element={<AddRdoPage />} />
      <Route path="/form/acessos" element={<AcessosPage />} />

      {IsTwoFactorRouter}
      {IsAuthenticatedRouter}
      {IsOwnerRoute}

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

MainRouter.displayName = 'MainRouter';

export default MainRouter;
