import { Box, Button, FormField, Heading, Layer, RadioButtonGroup } from 'grommet';
import { Close, Save, System } from 'grommet-icons';
import React, { ChangeEvent } from 'react';
import ReactCountryFlag from 'react-country-flag';
import useAppContext from '../Context/useAppContext';

export interface ConfigMenuOptionsProps {}

const ConfigMenuOptions: React.FC<ConfigMenuOptionsProps> = (): JSX.Element => {
  const [showConfig, setShowConfig] = React.useState<boolean>(false);
  const changeConfig = (): void => {
    setShowConfig(!showConfig);
  };

  const {
    state: { Theme, Language },
    dispatch,
  } = useAppContext();

  const changeLanguage = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: 'changeLanguage',
      value: event.target.value,
    });
  };

  const changeTheme = (): void => {
    dispatch({
      type: 'changeTheme',
      value: (Theme === 'light') ? 'dark' : 'light',
    });
  };

  return (
    <>
      <Button
        onClick={changeConfig}
        title="Open system configuration"
        icon={<System color='brand' />}
      />
      {
        (showConfig) && (
          <Layer
            onEsc={changeConfig}
            onClickOutside={changeConfig}
            responsive={true}
            position="right"
            modal={true}
            margin="none"
            background="background-back"
            full="vertical"
          >
            <Box
              fill="vertical"
              align="left"
              justify="start"
              pad="medium"
              gap="none"
              direction="column"
            >
              <Box
                fill="horizontal"
                align="end"
                pad="none"
                gap="none"
                margin="none"
              >
                <Button
                  icon={<Close />}
                  title="Close system configuration"
                  onClick={changeConfig}
                  pad="none"
                />
              </Box>

              <Heading
                level={3}
                textAlign="center"
                margin={{
                  top: 'small',
                }}
              >
                Configurações
              </Heading>

              <FormField
                label="Change Language"
              >
                <RadioButtonGroup
                  name="changeLanguage"
                  title="Change Language"
                  options={[
                    { label: <ReactCountryFlag countryCode="BR" aria-label="Português" title="Português" svg />, value: 'pt' },
                    { label: <ReactCountryFlag countryCode="US" aria-label="English" title="English" svg />, value: 'en' },
                  ]}
                  value={Language}
                  onChange={changeLanguage}
                  direction="row"
                  gap="medium"
                />
              </FormField>

              <FormField
                label="Change Theme"
              >
                <RadioButtonGroup
                  name="changeTheme"
                  title="Change theme"
                  options={[
                    { label: 'Light', value: 'light' },
                    { label: 'Dark', value: 'dark' },
                  ]}
                  value={Theme}
                  onChange={changeTheme}
                  direction="row"
                />
              </FormField>

              <Box
                pad={{
                  vertical: 'medium',
                }}
              >
                <Button
                  label="Save configuration"
                  title="Save system configuration"
                  icon={<Save />}
                  onClick={changeConfig}
                  primary
                />
              </Box>
            </Box>
          </Layer>
        )
      }
    </>
  );
};

ConfigMenuOptions.displayName = 'ConfigMenuOptions';

export default ConfigMenuOptions;
