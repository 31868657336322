import React from 'react';
import { Box } from 'grommet';
import { BoxProps } from 'grommet/components/Box';

interface PageWrapperProps extends BoxProps {
  children: React.ReactNode | React.ReactNode[];
  vAlign?: BoxProps['justify'];
  pad?: BoxProps['pad'];
}

const PageWrapper = (props: PageWrapperProps): JSX.Element => {
  const {
    children,
    vAlign = 'start',
    pad = 'medium',
    ...styles
  } = props;

  return (
    <Box
      flex
      fill
      align="center"
      justify={vAlign}
      pad={pad}
      overflow="auto"
      {...styles}
    >
      {children}
    </Box>
  );
};

PageWrapper.displayName = 'PageWrapper';

export default PageWrapper;
