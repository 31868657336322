import { Box, Paragraph } from 'grommet';
import styled from 'styled-components';

export const ChartTitle = styled(Paragraph)`
  font-size: 14px;
  font-weight: 600;
  align-self: flex-start;
  margin: 32px 0 16px;
  color: #1c1c1c;
`;

export const StatsBoxItemTitle = styled(Paragraph)`
  font-size: 12px;
  font-weight: 600;
  align-self: flex-start;
  margin: 0;
  color: #1c1c1c;

  @media only screen and (max-width: 768px) {
    align-self: center;
  }
`;

export const StatsBoxNumbersMain = styled(Paragraph)`
  font-size: 24px;
  font-weight: 600;
  color: #1c1c1c;
  margin: 0;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const StatsBoxNumbersInfo = styled(Paragraph)`
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  color: #1c1c1c;
`;

export const StatsBoxNumbers = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const StatsBoxItemWrapper = styled(Box)`
  display: flex;
  flex-direction: collum;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  background-color: #e3f5ff;
  padding: 24px;
  gap: 16px;

  @media only screen and (max-width: 768px) {
    gap: 8px;
  }

  :nth-child(even) { background: #E5ECF6; }
`;

export const StatsBoxContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 18px;
`;
