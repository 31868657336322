import React from 'react';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import DocumentosForm from '../../../Forms/Documentos/DocumentosForm';
import adicionarDocumentoInitialValues from '../../Helpers/adicionarDocumentoInitialValues';
import { useNavigate, useParams } from 'react-router-dom';
import adicionarDocumentoSubmit from '../../Helpers/adicionarDocumentoSubmit';
import { Box } from 'grommet';

export interface AdicionarDocumentoProps {}

const AdicionarDocumento: React.FC<AdicionarDocumentoProps> = ({}): JSX.Element => {
  const navigate = useNavigate();
  const { guid } = useParams<{ guid: string }>();

  const onCancel = (): void => {
    navigate(`/owner/manageEmpresaDocumentos/${guid}`);
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Documento"
      />
      <Box
        width="large"
      >
        <DocumentosForm
          initialValues={adicionarDocumentoInitialValues(guid!)}
          onSubmit={adicionarDocumentoSubmit}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarDocumento.displayName = 'AdicionarDocumento';

export default AdicionarDocumento;
