import { Box, Button, FormField, MaskedInput, Text } from 'grommet';
import { FastForward, Next, Previous, Rewind } from 'grommet-icons';
import React, { useState } from 'react';

export interface PaginationProps {
  page: number;
  last: number;
  onChange: (page: string) => void;
}

const Pagination: React.FC<PaginationProps> = ({ page, last, onChange }): JSX.Element => {
  const [temporaryPage, setTemporaryPage] = useState(page);


  const onClickRewind = (): void => {
    onChange('1');
    setTemporaryPage(1);
  };

  const onClickPrevious = (): void => {
    if (temporaryPage > 1) {
      onChange((temporaryPage - 1).toString());
      setTemporaryPage(temporaryPage - 1);
    } else if (temporaryPage <= 1) {
      setTemporaryPage(1);
    }
  };

  const onChangeMask = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    if (evt.target.value === '' || evt.target.value === undefined) {
      setTemporaryPage(1);
      onChange('1');
    } else {
      setTemporaryPage(
        parseFloat(evt.target.value),
      );
    }
  };

  const onKeyUpMask = (evt: React.KeyboardEvent<HTMLInputElement>): void => {
    if (evt.key === 'Enter' && temporaryPage >= last) {
      onChange(last.toString());
      setTemporaryPage(last);
    } else if (evt.key === 'Enter' && temporaryPage < last) {
      onChange(temporaryPage.toString());
      setTemporaryPage(temporaryPage);
    }
  };

  const onClickNext = (): void => {
    if (temporaryPage < last) {
      onChange((temporaryPage + 1).toString());
      setTemporaryPage(temporaryPage + 1);
    } else if (temporaryPage >= last) {
      setTemporaryPage(last);
    }
  };

  const onClickLast = (): void => {
    onChange(last.toString());
    setTemporaryPage(last);
  };

  return (
    <Box
      fill="horizontal"
      background="background-contrast"
      justify="center"
      align="center"
      pad="small"
      direction="row"
      gap="small"
      height={{
        min: '50px',
      }}
    >
      <Button
        plain
        title="Primeira página"
        icon={<Rewind />}
        disabled={!(temporaryPage !== 1)}
        onClick={onClickRewind}
      />
      <Button
        plain
        title="Anterior"
        icon={<Previous />}
        disabled={!(temporaryPage !== 1)}
        onClick={onClickPrevious}
      />
      <Text>
        Página
      </Text>
      <FormField
        width="small"
        margin={{
          left: '10px',
          right: '10px',
          top: '10px',
          bottom: '10px',
        }}
      >
        <MaskedInput
          value={temporaryPage}
          onChange={onChangeMask}
          onKeyUp={onKeyUpMask}
          mask={[
            {
              regexp: /d*/,
              placeholder: '1',
            },
          ]}
        />
      </FormField>
      <Text>
        de
      </Text>
      <Text>
        {last.toString()}
      </Text>
      <Button
        plain
        title="Próxima"
        icon={<Next />}
        disabled={(temporaryPage === last)}
        onClick={onClickNext}
      />
      <Button
        plain
        title="Última página"
        icon={<FastForward />}
        disabled={(temporaryPage === last)}
        onClick={onClickLast}
      />
    </Box>
  );
};

Pagination.displayName = 'Pagination';

export default Pagination;
