import React from 'react';
import { StatsBoxContainer } from './StatsBoxStiles';
import StatsBoxItem, { StatsBoxItemProps } from './Components/StatsBoxItem';
import { Box } from 'grommet';

export interface StatsBoxProps {}

const StatBoxMock: StatsBoxItemProps[] = [
  {
    id: '0',
    title: 'Bombas',
    result: '7,265',
    resultPercent: '11,02',
  },
  {
    id: '1',
    title: 'Roovers',
    result: '2,265',
    resultPercent: '8,02',
  },
  {
    id: '2',
    title: 'Locais',
    result: '11,445',
    resultPercent: '4,58',
  },
  {
    id: '3',
    title: 'Manutenção',
    result: '1,211',
    resultPercent: '1,09',
  },
];

const StatsBox: React.FC<StatsBoxProps> = ({}): JSX.Element => {
  return (
    <Box>
      <StatsBoxContainer>
        {StatBoxMock &&
          StatBoxMock.map((item) => (
            <StatsBoxItem
              key={item.id}
              title={item.title}
              result={item.result}
              resultPercent={item.resultPercent}
            ></StatsBoxItem>
          ))}
      </StatsBoxContainer>
    </ Box>
  );
};

StatsBox.displayName = 'StatsBox';

export default StatsBox;
