import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';

const adicionarDocumentoInitialValues = (empresaGuid: string): IEmpresaDocumento => {
  // add two years to the current date
  let dataVencimento: Date = new Date();
  dataVencimento.setFullYear(dataVencimento.getFullYear() + 2);

  return {
    Empresa: {
      Guid: empresaGuid,
    },
    Tipo: {
      Guid: '',
    },
    TipoDocumento: '',
    Upload: {
      UploadMimeType: '',
      UploadBase64: '',
      UploadExtension: '',
      UploadGuid: '',
    },
    UploadNome: '',
    Nome: '',
    Descricao: '',
    Ativo: true,
    Vencimento: false,
    // add two years to the current date
    DataVencimento: dataVencimento.toISOString(),
  };
};

export default adicionarDocumentoInitialValues;
