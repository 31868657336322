import React from 'react';
import { Box } from 'grommet';
import { IAddImagem } from '../../../../Interfaces/IAddImagem';
import AddImagemForm from './AddImagemForm';
import PageWrapper from '../../PageWrapper/PageWrapper';

export interface AddImagemPageProps {}

const AddImagemPage: React.FC<AddImagemPageProps> = ({}): JSX.Element => {
  const initialValues: IAddImagem = {
    imagem: undefined,
    titulo: '',
    observacao: '',
  };

  return (
    <PageWrapper>
      <Box width="xlarge" alignSelf="center" pad="xlarge">
        <AddImagemForm
          initialValues={initialValues}
          onSubmit={(values: IAddImagem): void => {
            console.log(values);
          }}
        />
      </Box>
    </PageWrapper>
  );
};

AddImagemPage.displayName = 'AddImagemPage';

export default AddImagemPage;
