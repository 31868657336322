import { Route } from 'react-router-dom';
import IsOwnerAuth from './IsOwnerAuth';
import ManageUsers from '../../ManageUsers/ManageUsers';
import TestingError from '../../TestingError/TestingError';
import Error404 from '../../Error404/Error404';
import EditUser from '../../ManageUsers/Components/EditUser/EditUser';
import ManageEmpresas from '../../ManageEmpresas/ManageEmpresas';
import AdicionarEmpresa from '../../ManageEmpresas/Components/AdicionarEmpresa/AdicionarEmpresa';
import ManageVideos from '../../ManageVideos/ManageVideos';
import VideoInsert from '../../ManageVideos/Components/VideoInsert/VideoInsert';
import VideoUpload from '../../ManageVideos/Components/VideoUpload/VideoUpload';
import VideoEdit from '../../ManageVideos/Components/VideoEdit/VideoEdit';
import EditarEmpresa from '../../ManageEmpresas/Components/EditarEmpresa/EditarEmpresa';
import InsertUsuario from '../../ManageUsers/Components/InsertUsuario/InsertUsuario';
import ManagePlantas from '../../ManagePlantas/ManagePlantas';
import AdicionarPlanta from '../../ManagePlantas/Components/AdicionarPlanta/AdicionarPlanta';
import ManageEmpresasDocumentos from '../../ManageEmpresasDocumentos/ManageEmpresasDocumentos';
import AdicionarDocumento from '../../ManageEmpresasDocumentos/Components/AdicionarDocumento/AdicionarDocumento';
import EditarPlanta from '../../ManagePlantas/Components/EditarPlanta/EditarPlanta';
import ManageEquipamentos from '../../ManageEquipamentos/ManageEquipamentos';
import AdicionarEquipamento from '../../ManageEquipamentos/Components/AdicionarEquipamento/AdicionarEquipamento';

const useIsOwnerRouter = (): JSX.Element => {
  return (
    <>
      <Route path="owner" element={<IsOwnerAuth />} >
        <Route path="testingError" element={<TestingError />} />
        {/* Manage Users */}
        <Route path="manageUsers" element={<ManageUsers />} />
        <Route path="editUser/:guid" element={<EditUser />} />
        <Route path="inserirUsuario" element={<InsertUsuario />} />
        {/* /Manage Users */}
        {/* Manage Empresas */}
        <Route path="manageEmpresas" element={<ManageEmpresas />} />
        <Route path="adicionarEmpresa" element={<AdicionarEmpresa />} />
        <Route path="editarEmpresa/:guid" element={<EditarEmpresa />} />
        {/* /Manage Empresas */}
        {/* Manage Videos */}
        <Route path="manageVideos" element={<ManageVideos />} />
        <Route path="videoInsert" element={<VideoInsert />} />
        <Route path="videoUpload/:guid" element={<VideoUpload />} />
        <Route path="videoEdit/:guid" element={<VideoEdit />} />
        {/* /Manage Videos */}
        {/* Manage Plantas */}
        <Route path="managePlantas/:guid" element={<ManagePlantas />} />
        <Route path="adicionarPlanta/:guid" element={<AdicionarPlanta />} />
        <Route path="editarPlanta/:guid" element={<EditarPlanta />} />
        {/* /Manage Plantas */}
        {/* Manage Documentos */}
        <Route path="manageEmpresaDocumentos/:guid" element={<ManageEmpresasDocumentos />} />
        <Route path="adicionarDocumento/:guid" element={<AdicionarDocumento />} />
        {/* /Manage Documentos */}
        <Route path="manageEquipamentos" element={<ManageEquipamentos />} />
        <Route path="adicionarEquipamento" element={<AdicionarEquipamento />} />
        {/* Manage Equipamentos */}
        <Route path="*" element={<Error404 />} />
        {/* /Manage Equipamentos */}
      </Route>
    </>
  );
};

export default useIsOwnerRouter;
