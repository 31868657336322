import { Route } from 'react-router-dom';
import IsAuthenticatedAuth from './IsAuthenticatedAuth';
import ImportCsv from '../../ImportCsv/ImportCsv';
import Error404 from '../../Error404/Error404';

const useIsAuthenticatedRouter = (): JSX.Element => {
  return (
    <>
      <Route path="auth" element={<IsAuthenticatedAuth />} >
        <Route path="importCsv" element={<ImportCsv />} />
        <Route path="*" element={<Error404 />} />
      </Route>
    </>
  );
};

export default useIsAuthenticatedRouter;
