import React from 'react';
import { Box } from 'grommet';
import { IConclusao } from '../../../../Interfaces/IConclusao';
import ConclusaoForm from './ConclusaoForm';
import PageWrapper from '../../PageWrapper/PageWrapper';

export interface ConclusaoPageProps {}

const ConclusaoPage: React.FC<ConclusaoPageProps> = ({}): JSX.Element => {
  const initialValues: IConclusao = {
    concluirProjeto: true,
    observacao: '',
  };

  return (
    <PageWrapper>
      <Box width="xlarge" alignSelf="center" pad="xlarge">
        <ConclusaoForm
          initialValues={initialValues}
          onSubmit={(values: IConclusao): void => {
            console.log(values);
          }}
        />
      </Box>
    </PageWrapper>
  );
};

ConclusaoPage.displayName = 'ConclusaoPage';

export default ConclusaoPage;
