import React from 'react';
import {
  Description,
  MainTitle,
  SmallTitle,
  TopSectionContainer,
  TopSectionContent,
  TopSectionImage,
} from './TopSectionLPStyles';
import { Button, Image } from 'grommet';
import { Next } from 'grommet-icons';

export interface TopSectionLPProps {}

const TopSectionLP: React.FC<TopSectionLPProps> = ({}): JSX.Element => {
  return (
    <TopSectionContainer>
      <TopSectionContent>
        <SmallTitle>QUEM SOMOS NÓS</SmallTitle>
        <MainTitle color="text-title">
          Explorando as profundezas com ROV’s: Tecnologia em veículos submarinos
        </MainTitle>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation.
        </Description>
        <Button
          primary
          label="Saiba Mais"
          icon={<Next size="small" color="#fff" />}
          reverse
        />
      </TopSectionContent>
      <TopSectionImage>
        <Image src="/main-image-lp.png" alt="" />
      </TopSectionImage>
    </TopSectionContainer>
  );
};

TopSectionLP.displayName = 'TopSectionLP';

export default TopSectionLP;
