import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  DateInput,
  FileInput,
  FormExtendedEvent,
  FormField,
  ResponsiveContext,
  TextArea,
} from 'grommet';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { IAddRdo } from '../../../../Interfaces/IAddRdo';

export interface AddRdoFormProps {
  initialValues: IAddRdo;
  onSubmit: (values: IAddRdo) => void;
}

const AddRdoForm: React.FC<AddRdoFormProps> = ({
  initialValues,
  onSubmit,
}): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<IAddRdo>(initialValues);
  const size = useContext(ResponsiveContext);

  const onChange = (nextValues: IAddRdo): void => setValues(nextValues);

  const onClear = (): void => setValues(initialValues);

  const handleSubmit = async (
    evt: FormExtendedEvent<IAddRdo>,
  ): Promise<void> => {
    setSubmitting(true);
    onSubmit(evt.value);
  };

  const validateObservation = (value: string): string | undefined => {
    if (value && (value.length < 3 || value.length > 300)) {
      return 'A observação deve ter entre 3 e 300 caracteres';
    }
  };

  return (
    <FormWrapper values={values} onChange={onChange} onSubmit={handleSubmit}>
      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="end">
        <FormField
          label="Adicionar Relatório diário de operações (RDO)"
          name="relatorio"
          width="100%"
          required
        >
          <FileInput
            name="relatorio"
            aria-label="Por favor adicione o relatório"
            title="Por favor adicione o relatório"
            accept="application/pdf"
            onChange={(event) => {
              const file = event?.target?.files?.[0] ?? undefined;
              setValues({ ...values, relatorio: file });
            }}
            messages={{
              browse: 'Carregar',
              dropPromptMultiple: 'Arraste seu relatório aqui ou',
              files: 'Relatório',
              remove: 'Remover',
              removeAll: 'Remover todos',
            }}
          />
        </FormField>
      </Box>

      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="end">
        <FormField
          label="Data"
          name="data"
          width="100%"
          required
        >
          <DateInput
            name="data"
            format="dd/mm/yyyy"
            calendarProps={{ locale: 'pt-BR' }}
            aria-label="Por favor selecione a data"
          />
        </FormField>
      </Box>

      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="end">
        <FormField
          label="Observação"
          name="observacao"
          width="100%"
          validate={validateObservation}
        >
          <TextArea
            name="observacao"
            aria-label="Por favor insira observações"
            title="Por favor insira observações"
            placeholder=""
          />
        </FormField>
      </Box>

      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="end">
        <FormField
          label="Assinatura digital"
          name="assinaturaDigital"
          width="100%"
        >
          <FileInput
            name="assinaturaDigital"
            aria-label="Por favor adicione a assinatura digital"
            title="Por favor adicione a assinatura digital"
            accept="application/pdf"
            onChange={(event) => {
              const file = event?.target?.files?.[0] ?? undefined;
              setValues({ ...values, assinaturaDigital: file });
            }}
            messages={{
              browse: 'Carregar',
              dropPromptMultiple: 'Arraste sua assinatura aqui ou',
              files: 'Assinatura',
              remove: 'Remover',
              removeAll: 'Remover todos',
            }}
          />
        </FormField>
      </Box>

      <Box direction="row" gap="medium" justify="start" margin={{ top: 'medium', bottom: 'medium' }}>
        <Button type="submit" label="Salvar" disabled={submitting} primary reverse />
        <Button label="Cancelar" onClick={onClear} reverse />
      </Box>
    </FormWrapper>
  );
};

AddRdoForm.displayName = 'AddRdoForm';

export default AddRdoForm;
