import React from 'react';
import { Box } from 'grommet';
import AcessosForm from './AcessosForm';

const AcessosPage: React.FC = () => {
  return (
    <Box pad="medium">
      <AcessosForm />
    </Box>
  );
};

export default AcessosPage;
