import React from 'react';
import {
  Box,
  Button,
  FormExtendedEvent,
  FormField,
  TextArea,
} from 'grommet';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { IConclusao } from '../../../../Interfaces/IConclusao';
import InterdevSwitch from '../../InterdevSwitch/InterdevSwitch';

export interface ConclusaoFormProps {
  initialValues: IConclusao;
  onSubmit: (values: IConclusao) => void;
}

const ConclusaoForm: React.FC<ConclusaoFormProps> = ({
  initialValues,
  onSubmit,
}): JSX.Element => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<IConclusao>(initialValues);
  

  const onChange = (nextValues: IConclusao): void => setValues(nextValues);

  const onClear = (): void => setValues(initialValues);

  const handleSubmit = async (
    evt: FormExtendedEvent<IConclusao>,
  ): Promise<void> => {
    setSubmitting(true);
    onSubmit(evt.value);
  };

  const validateObservacao = (value: string): string | undefined => {
    if (value && (value.length < 3 || value.length > 300)) {
      return 'O campo deve ter entre 3 e 300 caracteres';
    }
  };

  const activeChange = (value: boolean): void => {
    setValues({ ...values, concluirProjeto: value });
  };

  return (
    <FormWrapper values={values} onChange={onChange} onSubmit={handleSubmit}>
      <Box direction="column" gap="small">
        <Box direction="row" gap="small" justify="start" align="center">
          <InterdevSwitch
            activeLabel="Sim"
            inactiveLabel="Não"
            value={values.concluirProjeto || false}
            onChange={activeChange}
            name="concluirProjeto"
            title="Deseja concluir o projeto?"
            text="Concluir Projeto"
          />
        </Box>

        <FormField
          label="Observação"
          name="observacao"
          width="100%"
          validate={validateObservacao}
        >
          <TextArea
            name="observacao"
            aria-label="Por favor insira observações"
            title="Por favor insira observações"
            placeholder=""
          />
        </FormField>

        <Box direction="row" gap="medium" justify="start" margin={{ top: 'medium', bottom: 'medium' }}>
          <Button type="submit" label="Salvar" disabled={submitting} primary reverse />
          <Button label="Cancelar" onClick={onClear} reverse />
        </Box>
      </Box>
    </FormWrapper>
  );
};

ConclusaoForm.displayName = 'ConclusaoForm';

export default ConclusaoForm;
