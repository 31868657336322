import React, { useState } from 'react';
import { Box, Button, FormField, RadioButtonGroup, Select, CheckBox, Text } from 'grommet';
import { IAcessos } from '../../../../Interfaces/IAcessos';

const AcessosForm: React.FC = () => {
  const [values, setValues] = useState<IAcessos>({
    acessosCompleto: undefined,
    acessosPlanta: []
  });

  const [errors, setErrors] = useState<{ acessosCompleto?: string; acessosPlanta?: string }>({});

  const plantas = ['Planta 1', 'Planta 2', 'Planta 3']; // Exemplo de plantas

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const newErrors: { acessosCompleto?: string; acessosPlanta?: string } = {};
    if (values.acessosCompleto === undefined) {
      newErrors.acessosCompleto = 'Obrigatório';
    }
    if (values.acessosPlanta.length === 0) {
      newErrors.acessosPlanta = 'Obrigatório';
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      console.log(values);
    }
  };

  const handleCancel = () => {
    setValues({
      acessosCompleto: undefined,
      acessosPlanta: []
    });
    setErrors({});
  };

  const onSelectChange = (option: string) => {
    const newSelections = values.acessosPlanta.includes(option)
      ? values.acessosPlanta.filter((item) => item !== option)
      : [...values.acessosPlanta, option];
    setValues({ ...values, acessosPlanta: newSelections });
  };

  return (
    <Box pad="medium">
      <form onSubmit={handleFormSubmit}>
        <Box direction="row" gap="medium" justify="start">
          <FormField label="Acessos Completo" error={errors.acessosCompleto} required>
            <RadioButtonGroup
              name="acessosCompleto"
              options={['Sim', 'Não']}
              value={values.acessosCompleto === undefined ? '' : values.acessosCompleto ? 'Sim' : 'Não'}
              onChange={(event) => setValues({ ...values, acessosCompleto: event.target.value === 'Sim' })}
              direction="row"
            />
          </FormField>
        </Box>

        <Box direction="row" gap="medium" justify="start" margin={{ top: 'medium' }}>
          <FormField label="Acessos por planta" error={errors.acessosPlanta} required>
            <Select
              name="acessosPlanta"
              multiple
              options={plantas}
              value={values.acessosPlanta}
              onChange={({ value }) => setValues({ ...values, acessosPlanta: value })}
              placeholder="Selecione as plantas"
              closeOnChange={false}
              valueLabel={
                <Box direction="row" gap="xsmall" pad="xsmall">
                  {values.acessosPlanta.length > 0
                    ? values.acessosPlanta.map((item) => (
                        <Text key={item} weight="bold">
                          {item}
                        </Text>
                      ))
                    : 'Selecione as plantas'}
                </Box>
              }
            >
              {(option: string) => (
                <Box pad="small" key={option}>
                  <CheckBox
                    label={option}
                    checked={values.acessosPlanta.includes(option)}
                    onChange={() => onSelectChange(option)}
                  />
                </Box>
              )}
            </Select>
          </FormField>
        </Box>

        <Box direction="row" gap="small" justify="end" margin={{ top: 'medium', bottom: 'medium' }}>
          <Button
            type="submit"
            label="Salvar"
            title="Salvar"
            primary
            reverse
          />
          <Button
            type="button"
            label="Cancelar"
            title="Cancelar"
            onClick={handleCancel}
            secondary
          />
        </Box>
      </form>
    </Box>
  );
};

export default AcessosForm;
