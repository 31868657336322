import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import AppContextProvider from './components/Template/Context/AppContextProvider';
import i18n from './i18n';
import MainRouter from './components/Router/MainRouter';
import GrommetWrapper from './components/Template/GrommetWrapper/GrommetWrapper';
import { ICustomWindowWithData } from '../Interfaces/ICustomWindow';
import AppErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

declare const window: ICustomWindowWithData;

ReactDOM.hydrateRoot(document.getElementById('root')!, (
  <AppContextProvider context={window.__INITIAL_DATA__.context}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <GrommetWrapper>
            <AppErrorBoundary>
              <MainRouter />
            </AppErrorBoundary>
          </GrommetWrapper>
        </BrowserRouter>
      </I18nextProvider>
  </AppContextProvider>
));
