import React, { useState } from 'react';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import ManageEquipamentosActions from './ManageEquipamentosActions';

export interface ManageEquipamentosDataTableProps {
  equipamentos: IEquipamento[];
  refreshData: () => void;
}

const ManageEquipamentosDataTable: React.FC<ManageEquipamentosDataTableProps> = ({ equipamentos, refreshData }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Nome',
    direction: 'asc',
  });

  const columns: ColumnConfig<IEquipamento>[] = [
    {
      property: 'Nome',
      primary: false,
      search: true,
      sortable: true,
      header: 'Nome',
      render: (datum: IEquipamento): React.ReactNode => (
        <Text>{datum.Nome}</Text>
      ),
    },
    {
      property: 'Modelo',
      primary: false,
      search: true,
      sortable: true,
      header: 'Modelo',
      render: (datum: IEquipamento): React.ReactNode => (
        <Text>{datum.Modelo}</Text>
      ),
    },
    {
      property: 'NumeroDeSerie',
      primary: false,
      search: true,
      sortable: true,
      header: 'Série',
      render: (datum: IEquipamento): React.ReactNode => (
        <Text>{datum.NumeroDeSerie}</Text>
      ),
    },
    {
      property: 'Ano',
      primary: false,
      search: true,
      sortable: true,
      header: 'Ano',
      render: (datum: IEquipamento): React.ReactNode => (
        <Text>{datum.Ano}</Text>
      ),
    },
    {
      property: 'Certificado',
      primary: false,
      search: true,
      sortable: true,
      header: 'Certificado',
      render: (datum: IEquipamento): React.ReactNode => (
        <Text>{datum.Certificado}</Text>
      ),
    },
    {
      property: 'Ativo',
      primary: false,
      search: false,
      sortable: true,
      header: 'Ativo?',
      render: (datum: IEquipamento): React.ReactNode => (
        <Text>{(datum.Ativo) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Guid',
      primary: true,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IEquipamento): React.ReactNode => (
        <ManageEquipamentosActions
          equipamento={datum}
          refreshData={refreshData}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={equipamentos}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ManageEquipamentosDataTable.displayName = 'ManageEquipamentosDataTable';

export default ManageEquipamentosDataTable;
