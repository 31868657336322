import React from 'react';
import PermissoesForm from './PermissoesForm';
import { Box } from 'grommet';

export interface PermissoesPageProps {}

const PermissoesPage: React.FC<PermissoesPageProps> = ({}): JSX.Element => {
  const initialValues = {
    userName: '',
    userEmail: '',
    isAdmin: 'Usuário', // Valor inicial padrão
    isActive: 'Ativo',  // Valor inicial padrão
    fullAccess: 'Não',  // Valor inicial padrão
    plantAccess: [],
  };

  const plantsList = ['Planta 1', 'Planta 2', 'Planta 3']; // Exemplo de lista de plantas

  return (
    <Box
      width="large"
      alignSelf="center"
      pad="xlarge"
    >
      <PermissoesForm
        initialValues={initialValues}
        onSubmit={(values): void => { console.log(values); }}
        plantsList={plantsList}
      />
    </Box>
  );
};

PermissoesPage.displayName = 'PermissoesPage';

export default PermissoesPage;
