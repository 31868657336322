import React from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import {
  ErrorDescription,
  ErrorPageContainer,
  ErrorTag,
  ErrorTitle,
} from './Error404Styles';
import { Button } from 'grommet';

export interface Error404Props {}

const Error404: React.FC<Error404Props> = ({}): JSX.Element => {
  return (
    <PageWrapper justify="center">
      <ErrorPageContainer>
        <ErrorTag color="text-strong">404</ErrorTag>
        <ErrorTitle color="text-strong">Opss! Esta página não está mais disponível.</ErrorTitle>
        <ErrorDescription color="text-strong">
          Desculpe mas esta página que você está procurando não existe mais. Ela pode ter sido removida,
          trocada de nome ou estar temporariamente indisponível.
        </ErrorDescription>
        <Button
          primary
          label="VOLTAR PARA HOME"
        />
      </ErrorPageContainer>
    </PageWrapper>
  );
};

Error404.displayName = 'Error404';

export default Error404;
