import { ColumnConfig, DataTable, Text } from 'grommet';
import React, { useState } from 'react';
import { IStreamingVideo } from '../../../../Interfaces/IStreamingVideo';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import ManageVideosActions from './ManageVideosActions';

export interface ManageVideosDataTableProps {
  videos: IStreamingVideo[];
  refreshData: () => void;
}

const ManageVideosDataTable: React.FC<ManageVideosDataTableProps> = ({ videos, refreshData }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Title',
    direction: 'desc',
  });

  const columns: ColumnConfig<IStreamingVideo>[] = [
    {
      property: 'Title',
      primary: false,
      search: true,
      sortable: true,
      header: 'Título',
      render: (datum: IStreamingVideo): React.ReactNode => (
        <Text>{datum.Title}</Text>
      ),
    }, {
      property: 'Description',
      primary: false,
      search: true,
      sortable: true,
      header: 'Descrição',
      render: (datum: IStreamingVideo): React.ReactNode => (
        <Text>{datum.Description}</Text>
      ),
    },
    {
      property: 'Guid',
      primary: false,
      search: false,
      sortable: true,
      header: 'Ações',
      render: (datum: IStreamingVideo): React.ReactNode => (
        <ManageVideosActions
          video={datum}
          refreshData={refreshData}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={videos}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={false}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ManageVideosDataTable.displayName = 'ManageVideosDataTable';

export default ManageVideosDataTable;
