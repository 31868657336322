import React, { useState } from 'react';
import { ColumnConfig, DataTable, Text } from 'grommet';
import { IUser } from '../../../../Interfaces/IUser';
import { IDataTableSort } from '../../../../Interfaces/IDataTableSort';
import ManageUsersActions from './ManageUsersActions';

export interface ManageUsersTableProps {
  users: IUser[];
  refreshData: () => void;
}

const ManageUsersTable: React.FC<ManageUsersTableProps> = ({ users, refreshData }): JSX.Element => {
  const [sort, setSort] = useState<IDataTableSort>({
    property: 'Email',
    direction: 'asc',
  });

  const columns: ColumnConfig<IUser>[] = [
    {
      property: 'FirstName',
      primary: false,
      search: false,
      sortable: true,
      header: 'Nome',
      render: (datum: IUser): React.ReactNode => (
        <Text>{datum.FirstName}</Text>
      ),
    },
    {
      property: 'LastName',
      primary: false,
      search: false,
      sortable: true,
      header: 'Sobrenome',
      render: (datum: IUser): React.ReactNode => (
        <Text>{datum.LastName}</Text>
      ),
    },
    {
      property: 'Email',
      primary: false,
      search: true,
      sortable: true,
      header: 'Email',
      render: (datum: IUser): React.ReactNode => (
        <Text>{datum.Email}</Text>
      ),
    },
    {
      property: 'Owner',
      primary: false,
      search: false,
      sortable: true,
      header: 'Owner',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Owner) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Technician',
      primary: false,
      search: false,
      sortable: true,
      header: 'Técnico',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Technician) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Active',
      primary: false,
      search: false,
      sortable: true,
      header: 'Ativo?',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Active) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Validated',
      primary: false,
      search: false,
      sortable: true,
      header: 'Validado?',
      render: (datum: IUser): React.ReactNode => (
        <Text>{(datum.Validated) ? 'Sim' : 'Não'}</Text>
      ),
    },
    {
      property: 'Guid',
      primary: true,
      search: false,
      sortable: false,
      header: 'Ações',
      render: (datum: IUser): React.ReactNode => (
        <ManageUsersActions
          user={datum}
          refreshData={refreshData}
        />
      ),
    },
  ];

  return (
    <DataTable
      fill="horizontal"
      data={users}
      background={{
        body: ['background', 'background-front'],
      }}
      border={{
        body: 'bottom',
      }}
      sortable={true}
      sort={sort}
      onSort={setSort}
      pad={{
        horizontal: 'small',
        vertical: 'xsmall',
      }}
      rowProps={{
        true: {
          background: 'dark-brand',
        },
      }}
      columns={columns}
    />
  );
};

ManageUsersTable.displayName = 'ManageUsersTable';

export default ManageUsersTable;
