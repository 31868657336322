import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ITipoDeDocumento } from '../../../Interfaces/ITipoDeDocumento';
import getDropDownTiposDeDocumentos from './Helpers/getDropDownTiposDeDocumentos';
import { FormField, Select, Spinner } from 'grommet';

export interface DropDownTiposDeDocumentosProps {
  name: string;
  label: string;
  title: string;
  disabled?: boolean;
}

const DropDownTiposDeDocumentos: React.FC<DropDownTiposDeDocumentosProps> = ({
  name, label, title, disabled,
}): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tiposDeDocumentos, setTiposDeDocumentos] = useState<ITipoDeDocumento[]>([]);

  const getInitialData = async (): Promise<void> => {
    setLoading(true);

    await getDropDownTiposDeDocumentos()
      .then((data): void => {
        if (data.Success) {
          setTiposDeDocumentos(data.Object!);
        } else {
          toast.error('Erro ao buscar tipos de documentos');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar tipos de documentos');
      });
  };

  // const validate = (datum: string): string | undefined => {
  //   if (datum === '') {
  //     return 'Selecione um tipo de documento';
  //   }
  // };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (loading) ? (<Spinner />) : (
    <FormField
      label={label}
      name={name}
      width="100%"
      // validate={validate}
      required
    >
      <Select
        name="TipoDocumento"
        aria-label={title}
        title={title}
        placeholder={label}
        options={tiposDeDocumentos}
        valueKey={{ key: 'Guid', reduce: true }}
        labelKey="Nome"
        disabled={disabled}
      />
    </FormField>
  );
};

DropDownTiposDeDocumentos.displayName = 'DropDownTiposDeDocumentos';

export default DropDownTiposDeDocumentos;
