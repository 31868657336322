import React from 'react';
import { Box } from 'grommet';
import { IAddRdo } from '../../../../Interfaces/IAddRdo';
import AddRdoForm from './AddRdoForm';
import PageWrapper from '../../PageWrapper/PageWrapper';

export interface AddRdoPageProps {}

const AddRdoPage: React.FC<AddRdoPageProps> = ({}): JSX.Element => {
  const initialValues: IAddRdo = {
    relatorio: undefined,
    data: '',
    observacao: '',
    assinaturaDigital: undefined,
  };

  return (
    <PageWrapper>
      <Box width="xlarge" alignSelf="center" pad="xlarge">
        <AddRdoForm
          initialValues={initialValues}
          onSubmit={(values: IAddRdo): void => {
            console.log(values);
          }}
        />
      </Box>
    </PageWrapper>
  );
};

AddRdoPage.displayName = 'AddRdoPage';

export default AddRdoPage;
