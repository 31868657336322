import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { Box } from 'grommet';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../Loading/Loading';
import PlantaForm from '../../../Forms/Planta/PlantaForm';
import { IPlanta } from '../../../../../Interfaces/IPlanta';
import getPlantaInfoByGuid from '../../Helpers/editarPlantaInitialValues';
import editarPlantaSubmit from '../../Helpers/editarPlantaSubmit';

export interface EditarPlantaProps {}

const EditarPlanta: React.FC<EditarPlantaProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [planta, setPlanta] = useState<IPlanta>({});

  const onCancel = (): void => navigate(`/owner/managePlantas/${planta.Empresa!.Guid}`);

  const getInitialData = async (): Promise<void> => {
    await getPlantaInfoByGuid(guid!)
      .then((data): void => {
        if (data.Success) {
          const initialValues: IPlanta = data.Object!;
          setPlanta(initialValues);
        } else {
          toast.error('Erro ao buscar informações da planta');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar informações da planta');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Editar planta"
      />
      <Box
        width="large"
      >
        {
          (loading) ? (<Loading />)
            : (
              <PlantaForm
                initialValues={planta}
                onSubmit={editarPlantaSubmit}
                onCancel={onCancel}
              />
            )
        }
      </Box>
    </PageWrapper>
  );
};

EditarPlanta.displayName = 'EditarPlanta';

export default EditarPlanta;
