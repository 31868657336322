import { IEquipamento } from '../../../../Interfaces/IEquipamento';

const inserirEquipamentoInitialValues = (): IEquipamento => ({
  Nome: '',
  NumeroDeSerie: '',
  Ano: new Date().getFullYear(),
  Modelo: '',
  Certificado: '',
  Observacao: '',
  Ativo: true,
});

export default inserirEquipamentoInitialValues;