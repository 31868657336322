import {
  Box, Button, FormExtendedEvent, FormField, ResponsiveContext, TextInput,
} from 'grommet';
import React, { useContext, useState } from 'react';
import { IUser } from '../../../../Interfaces/IUser';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { onEmailValidationRegExp } from '../../../../utils/onEmailRegexValidation';

export interface ChangeEmailFromAdminViewFormProps {
  initialValues: IUser;
  onSubmit: (values: IUser) => void;
  closeModal: () => void;
}

const ChangeEmailFromAdminViewForm: React.FC<ChangeEmailFromAdminViewFormProps> = ({ initialValues, onSubmit, closeModal }): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<IUser>(initialValues);
  const onChange = (nextValues: IUser): void => setValues(nextValues);

  const size = useContext(ResponsiveContext);

  const onCancel = (): void => {
    setValues(initialValues);
    closeModal();
  };

  const handleSubmit = async (evt: FormExtendedEvent<IUser>): Promise<void> => {
    const { value } = evt;
    setSubmitting(true);

    await onSubmit(value);

    setSubmitting(false);
  };

  const validateConfirmEmail = (value: string): string | undefined => {
    if (value !== values.Email) {
      return 'Emails não conferem';
    }
    return undefined;
  };

  return (
    <FormWrapper
      values={values}
      onChange={onChange}
      onSubmit={handleSubmit}
    >
      <Box
        gap="small"
        direction={(size === 'small') ? 'column' : 'row'}
      >
        <FormField
          label="Email"
          name="Email"
          width="100%"
          validate={onEmailValidationRegExp('Email inválido')}
          required
        >
          <TextInput
            name="Email"
            type="email"
            title="Novo email"
          />
        </FormField>

        <FormField
          label="Confirmar Email"
          name="ConfirmEmail"
          width="100%"
          validate={validateConfirmEmail}
        >
          <TextInput
            name="ConfirmEmail"
            title="Confirmar Email"
          />
        </FormField>
      </Box>

      <Box
        direction="row"
        gap="small"
        justify="start"
        margin={{ top: 'medium', bottom: 'medium' }}
      >
        <Button
          type="submit"
          label="Salvar"
          title="Salvar"
          disabled={submitting}
          primary
          reverse
        />
        <Button
          label="Cancelar"
          title="Cancelar"
          onClick={onCancel}
          reverse
        />
      </Box>
    </FormWrapper>
  );
};

ChangeEmailFromAdminViewForm.displayName = 'ChangeEmailFromAdminViewForm';

export default ChangeEmailFromAdminViewForm;
