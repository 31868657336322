import { Box, Button, Heading, Layer, Paragraph } from 'grommet';
import React from 'react';

export interface ConfirmModalProps {
  title: string;
  text: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ title, text, onConfirm, onCancel }): JSX.Element => {
  return (
    <Layer
      position="center"
      onClickOutside={onCancel}
      onEsc={onCancel}
    >
      <Box
        pad="medium"
        gap="small"
        width="large"
      >
        <Heading
          level={3}
          margin="none"
          textAlign="center"
          fill
        >
          {title}
        </Heading>
        <Paragraph fill>
          {text}
        </Paragraph>
        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="end"
          pad={{
            top: 'medium',
            bottom: 'small',
          }}
        >
          <Button
            label="Cancelar"
            onClick={onCancel}
            secondary
          />
          <Button
            label="Confirmar"
            onClick={onConfirm}
            size="large"
            primary
          />
        </Box>
      </Box>
    </Layer>
  );
};

ConfirmModal.displayName = 'ConfirmModal';

export default ConfirmModal;
