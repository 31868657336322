import React from 'react';
import { Box } from 'grommet';
import { IAddRelatorio } from '../../../../Interfaces/IAddRelatorio';
import AddRelatorioForm from './AddRelatorioForm';
import PageWrapper from '../../PageWrapper/PageWrapper';

export interface AddRelatorioPageProps {}

const AddRelatorioPage: React.FC<AddRelatorioPageProps> = ({}): JSX.Element => {
  const initialValues: IAddRelatorio = {
    relatorio: undefined, // Valor inicial definido como undefined
    titulo: '',
    observacao: '',
  };

  return (
    <PageWrapper>
      <Box width="xlarge" alignSelf="center" pad="xlarge">
        <AddRelatorioForm
          initialValues={initialValues}
          onSubmit={(values: IAddRelatorio): void => {
            console.log(values);
          }}
        />
      </Box>
    </PageWrapper>
  );
};

AddRelatorioPage.displayName = 'AddRelatorioPage';

export default AddRelatorioPage;
