const initialContext = {
  Theme: 'light',
  Language: 'pt',
  ConsentCookie: {
    ShowBanner: true,
    CookieString: '',
  },
  Guid: '',
  FirstName: '',
  LastName: '',
  Email: '',
  KeepConnected: false,
  Drawer: false,
  IsAuthenticated: false,
  Config: false,
  Validated: false,
  NickName: '',
};

export default initialContext;
