import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  FileInput,
  FormExtendedEvent,
  FormField,
  ResponsiveContext,
  TextInput,
  TextArea,
} from 'grommet';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { IAddImagem } from '../../../../Interfaces/IAddImagem';

export interface AddImagemFormProps {
  initialValues: IAddImagem;
  onSubmit: (values: IAddImagem) => void;
}

const AddImagemForm: React.FC<AddImagemFormProps> = ({
  initialValues,
  onSubmit,
}): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<IAddImagem>(initialValues);
  const size = useContext(ResponsiveContext);

  const onChange = (nextValues: IAddImagem): void => setValues(nextValues);

  const onClear = (): void => setValues(initialValues);

  const handleSubmit = async (
    evt: FormExtendedEvent<IAddImagem>,
  ): Promise<void> => {
    setSubmitting(true);
    onSubmit(evt.value);
  };

  const validateTitle = (value: string): string | undefined => {
    if (value && (value.length < 3 || value.length > 100)) {
      return 'O título deve ter entre 3 e 100 caracteres';
    }
  };

  const validateObservation = (value: string): string | undefined => {
    if (value && (value.length < 3 || value.length > 300)) {
      return 'A observação deve ter entre 3 e 300 caracteres';
    }
  };

  return (
    <FormWrapper values={values} onChange={onChange} onSubmit={handleSubmit}>
      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="end">
        <FormField
          label="Adicionar imagem"
          name="imagem"
          width="100%"
          required
        >
          <FileInput
            name="imagem"
            aria-label="Por favor adicione a imagem"
            title="Por favor adicione a imagem"
            onChange={(event) => {
              const file = event?.target?.files?.[0] ?? undefined;
              setValues({ ...values, imagem: file });
            }}
            messages={{
              browse: 'Carregar',
              dropPromptMultiple: 'Arraste sua imagem aqui ou',
              files: 'Imagem',
              remove: 'Remover',
              removeAll: 'Remover todos',
            }}
          />
        </FormField>
      </Box>

      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="end">
        <FormField
          label="Título"
          name="titulo"
          width="100%"
          validate={validateTitle}
        >
          <TextInput
            name="titulo"
            aria-label="Por favor informe o título"
            title="Por favor informe o título"
          />
        </FormField>
      </Box>

      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="end">
        <FormField
          label="Observação"
          name="observacao"
          width="100%"
          validate={validateObservation}
        >
          <TextArea
            name="observacao"
            aria-label="Por favor insira observações"
            title="Por favor insira observações"
            placeholder=""
          />
        </FormField>
      </Box>

      <Box direction="row" gap="medium" justify="start" margin={{ top: 'medium', bottom: 'medium' }}>
        <Button type="submit" label="Salvar" disabled={submitting} primary reverse />
        <Button label="Cancelar" onClick={onClear} reverse />
      </Box>
    </FormWrapper>
  );
};

AddImagemForm.displayName = 'AddImagemForm';

export default AddImagemForm;
