import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import { Edit } from 'grommet-icons';

export interface ManageEquipamentosActionsProps {
  equipamento: IEquipamento;
  refreshData: () => void;
}

const ManageEquipamentosActions: React.FC<ManageEquipamentosActionsProps> = ({ equipamento }): JSX.Element => {
  const navigate = useNavigate();

  const onEditEquipamentoClick = (): void => {
    navigate(`/owner/editarEquipamento/${equipamento.Guid}`);
  };
  return (
    <>
      <Box
        direction="row"
        gap="none"
        pad="none"
      >
        <Button
          icon={<Edit />}
          onClick={onEditEquipamentoClick}
        />
      </Box>
    </>
  );
};

ManageEquipamentosActions.displayName = 'ManageEquipamentosActions';

export default ManageEquipamentosActions;
