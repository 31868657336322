import { Anchor, Box, Text } from 'grommet';
import styled from 'styled-components';

export const AnchorInterdev = styled(Anchor)`
  font-size: 16px;
  margin-bottom: 6px;
  padding-left: 08px;
  font-weight: 400;
  color: #929a9f;

  @media only screen and (max-width: 768px) {
  }
`;

export const TitleSectionSideBar = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.35;
  margin: 8px 0;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const SectionSideBarContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  @media only screen and (max-width: 768px) {
  }
`;
