import {
  Box, Button, DateInput, FormExtendedEvent, FormField, RadioButtonGroup, ResponsiveContext, Select, TextInput,
} from 'grommet';
import React, { useContext } from 'react';
import FormWrapper from '../../FormWrapper/FormWrapper';
import { IProjeto } from '../../../../Interfaces/IProjeto';
  
export interface ProjetoFormProps {
  initialValues: IProjeto;
  onSubmit: (values: IProjeto) => void;
}
  
const ProjetoForm: React.FC<ProjetoFormProps> = ({ initialValues, onSubmit }): JSX.Element => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  
  const [values, setValues] = React.useState<IProjeto>(initialValues);
  const onChange = (nextValues: IProjeto): void => setValues(nextValues);
  
  const onClear = (): void => { setValues(initialValues); };
  const [] = React.useState(false);
  
  const handleSubmit = async (evt: FormExtendedEvent<IProjeto>): Promise<void> => {
    setSubmitting(true);
    onSubmit(evt.value);
  };
  
  const size = useContext(ResponsiveContext);
  
  return (
      <FormWrapper
        values={values}
        onChange={onChange}
        onSubmit={handleSubmit}
      >
        <Box
          direction={ (size === 'small') ? 'column' : 'row' }
          gap="small"
          justify="end"
        >
          <FormField
            label="Título do Projeto"
            name="TituloProjeto"
            width="100%"
            required
          >
            <TextInput
              name="TituloProjeto"
              aria-label="Por favor informe o Título do Projeto"
              title="Por favor informe o Título do Projeto"
            />
          </FormField>
  
          <FormField
            label="Número do Projeto"
            name="NumeroProjeto"
            width="100%"
            required
          >
            <TextInput
              name="NumeroProjeto"
              aria-label="Por favor informe o Número do Projeto"
              title="Por favor informe o Número do Projeto"
            />
          </FormField>
        </Box>
  
        <Box
          direction={(size === 'small') ? 'column' : 'row'}
          gap="small"
          justify="end"
        >
            <FormField
            label="Escopo"
            name="Escopo"
            width="100%"
            required
          >
            <TextInput
              name="Escopo"
              aria-label="Por favor informe o Escopo do Projeto"
              title="Por favor informe o Escopo do Projeto"
            />
          </FormField>

          <FormField
            label="Tipo de Serviço"
            name="tipoServico"
            width="100%"
            required
          >
            <RadioButtonGroup
              name="tipoServico"
              options={['OnShore', 'OffShore']}
              value={values.tipoServico}
              onChange={event => setValues({ ...values, tipoServico: event.target.value })}
            />
          </FormField>
        </Box>
  
        <Box
          direction={(size === 'small') ? 'column' : 'row'}
          gap="small"
          justify="end"
        >
          <FormField
            label="Cliente"
            name="Cliente"
            width="100%"
            required
          >
            <TextInput
              name="Cliente"
              aria-label="Por favor informe o Cliente"
              title="Por favor informe o Cliente"
            />
          </FormField>
  
          <FormField
            label="Planta"
            name="Planta"
            width="100%"
            required
          >
            <TextInput
              name="Planta"
              aria-label="Por favor informe a Planta"
              title="Por favor informe a Planta"
            />
          </FormField>
        </Box>
  
        <Box
          direction={(size === 'small') ? 'column' : 'row'}
          gap="small"
          justify="end"
        >
          <FormField
            label="Previsão"
            name="previsaoProjetos"
            width="100%"
          >
            <Select
              options={['1', '2', '3', '4', '5']}
              value={'1' || '2' || '3' || '4' || '5'}
            />
          </FormField>
  
          <FormField
            label="Previsão"
            name="previsaoData"
            width="100%"
          >
            <Select
              options={['horas', 'dias', 'meses']}
              value={'horas' || 'dias' || 'meses'}
            />
          </FormField>
        </Box>

        <Box
          direction={(size === 'small') ? 'column' : 'row'}
          gap="small"
          justify="end"
        >
          <FormField
            label="Data de Início"
            name="DataInicio"
            width="100%"
            required
          >
            <DateInput
            name="DataInicio"
            format="mm/dd/yyyy"
            value={(new Date()).toISOString()}
            onChange={({ }) => {}}
            />
          </FormField>

          <FormField
            label="Data de Término"
            name="DataTermino"
            width="100%"
            required
          >
            <DateInput
            name="DataTermino"
            format="mm/dd/yyyy"
            value={(new Date()).toISOString()}
            onChange={({ }) => {}}
            />
          </FormField>
  
          <FormField
            label="Local de Atendimento"
            name="LocalAtendimento"
            width="100%"
            required
          >
            <TextInput
              name="LocalAtendimento"
              aria-label="Por favor informe o Local de Atendimento"
              title="Por favor informe o Local de Atendimento"
            />
          </FormField>
        </Box>
        <Box
          direction={(size === 'small') ? 'column' : 'row'}
          gap="small"
          justify="end"
        >
          <FormField
            label="Equipe Técnica"
            name="EquipeTecnica"
            width="100%"
            required
          >
            <TextInput
              name="EquipeTecnica"
              aria-label="Por favor informe a Equipe Técnica"
              title="Por favor informe  a Equipe Técnica"
            />
          </FormField>
  
          <FormField
            label="Equipamento"
            name=" Equipamento"
            width="100%"
            required
          >
            <TextInput
              name="Equipamento"
              aria-label="Por favor informe o Equipamento"
              title="Por favor informe o Equipamento"
            />
          </FormField>
        </Box>
        <Box
          direction={(size === 'small') ? 'column' : 'row'}
          gap="small"
          justify="end"
        >
            <FormField
                label="Observações"
                name="Observacoes"
                width="100%"
            >
                <TextInput
                name="Observacoes"
                aria-label="Por favor informe as Observações"
                title="Por favor informe as Observações"
                />
            </FormField>
        </Box>
  
        <Box
          direction="row"
          gap="medium"
          justify="start"
          margin={{
            top: 'medium',
            bottom: 'medium',
          }}
        >
          <Button
            type="submit"
            label="Salvar"
            title="Salvar"
            disabled={submitting}
            primary
            reverse
          />
          <Button
            label="Cancelar"
            title="Cancelar"
            onClick={onClear}
            reverse
          />
        </Box>
      </FormWrapper>
  );
};
  
ProjetoForm.displayName = 'ProjetoForm';
  
export default ProjetoForm;
  